import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

type PropsTypes = {
  message: string;
  success: boolean;
};
const SnackBarComponent = ({ message, success }: PropsTypes) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = (
    event: Event | React.SyntheticEvent<unknown, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity={success ? 'success' : 'error'}>
          <AlertTitle>{success ? 'Sucesso' : 'Error'}</AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default SnackBarComponent;
