import React, { Fragment } from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

type PageProps = {
  children: React.ReactNode;
};
const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const PageContainer = ({ children }: PageProps) => {
  return (
    <Fragment>
      <Offset sx={{ margin: 2 }} />
      <Container
        maxWidth={false}
        sx={{ height: '91.5vh', maxWidth: '100%', padding: '0 !important', margin: '0' }}
      >
        <Grid container spacing={3} sx={{ height: '100%', maxWidth: '100%', margin: '0' }}>
          {children}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default PageContainer;
