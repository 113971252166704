import * as React from 'react';
import { useController } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import FormErrors from './FormError';

type PropsTypes = {
  name: string;
  label: string;
  emptylabel: string;
  options: { key: string; value: string }[];
};
const SelectField = ({ name, label, emptylabel, options }: PropsTypes) => {
  const { field, fieldState } = useController({ name });
  return (
    <>
      <TextField
        select
        name={name}
        value={field.value}
        label={label}
        onChange={field.onChange}
        onBlur={field.onBlur}
        error={Boolean(fieldState.error)}
        SelectProps={{
          displayEmpty: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
      >
        <MenuItem key="0" value="">
          --- {emptylabel} ---
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </TextField>
      <FormErrors errors={fieldState.error} />
    </>
  );
};

export default SelectField;
