import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1272,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#014173',
      light: '#2d5a7d',
      dark: '#062a46',
    },
    secondary: {
      main: '#f6782a',
      light: '#f6985d',
      dark: '#d86b26',
    },
    success: {
      main: '#1fa824',
    },
    warning: {
      main: '#ffce22',
    },
    error: {
      main: '#d81212',
    },
    info: {
      main: '#0980d6',
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 80,
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h2' },
          style: {
            fontSize: '1.25em',
            fontWeight: 700,
          },
        },
        {
          props: { variant: 'body1' },
          style: {
            fontSize: '25px',
            fontWeight: 'bold',
          },
        },
      ],
    },
  },
});

export default theme;
