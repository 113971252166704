import { CircularProgress, Grid } from '@mui/material';
import React from 'react';

export type LoadingProps = {
  size: number;
};
export function Loading({ size }: LoadingProps) {
  return (
    <Grid item xs={12} md="auto" m={6}>
      <CircularProgress size={size} disableShrink />
    </Grid>
  );
}
