import React from 'react';

import { FormProvider, UseFormReturn } from 'react-hook-form';

import Grid from '@mui/material/Grid';

import { OpportunityBankCard } from '../../components/OpportunityBankCard';
import SnackBarComponent from '../../components/SnackBarComponent';
import { MainHeader } from '../../components/MainHeader';
import SearchComponent from '../../components/SearchComponent';
import PageContainer from '../../components/PageContainer';
import PageTitle from '../../components/PageTitle';
import { OpportunitiesCardList } from '../../components/OpportunitiesCardList';
import RegisterModal from '../../components/RegisterModal';
import { Loading } from '../../components/Loading';
import SuccessDialog from '../../components/SuccessDialog';
import { LOADING_SIZE } from '../../constants';

type Contact = {
  name: string;
  state: string;
  interest_department: string;
  preferences: string;
  resume: never[];
};
type SearchJobOpportunity = {
  search: string;
};
type PropsType = {
  opportunityList: {
    id: string;
    title: string;
    state: string;
    open_opportunities: number;
    requirements: string;
    city: string;
    type_opportunities_name: string;
    is_pcd: boolean;
  }[];
  responseSuccess: boolean;
  responseError: boolean;
  message: string;
  open: boolean;
  hasjobOpportunity: boolean;
  departments: { key: string; value: string }[];
  isLoading: boolean;
  openSuccessDialog: boolean;
  methods: UseFormReturn<
    {
      name: string;
      state: string;
      interest_department: string;
      preferences: string;
      resume: never[];
    },
    unknown
  >;
  searchMethods: UseFormReturn<
    {
      search: string;
    },
    unknown
  >;
  openModal: (id?: string) => void;
  handleClose: () => void;
  onSubmit: (data: Contact) => void;
  searchJobOpportunity: (data: SearchJobOpportunity) => void;
  openModalBank: () => void;
  handleCloseSuccessDialog: () => void;
  loadJobOpportunities: (data?: React.FormEvent<HTMLDivElement>) => void;
};
export default function JobOpportunity({
  opportunityList,
  open,
  openModal,
  handleClose,
  methods,
  onSubmit,
  openModalBank,
  hasjobOpportunity,
  departments,
  responseSuccess,
  responseError,
  message,
  searchJobOpportunity,
  searchMethods,
  isLoading,
  openSuccessDialog,
  handleCloseSuccessDialog,
}: PropsType) {
  return (
    <PageContainer>
      <MainHeader logoLink="https://www.mcsmarkup.com/" />
      {isLoading ? (
        <Grid item xs={12} textAlign="center">
          <Loading size={LOADING_SIZE.MEDIUM} />
        </Grid>
      ) : (
        <Grid container px={2}>
          <Grid item xs>
            <Grid item xs={12} mb={4}>
              <OpportunityBankCard openModalBank={() => openModalBank()} />
            </Grid>

            {opportunityList.length > 0 ? (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <FormProvider {...searchMethods}>
                      <Grid
                        component="form"
                        container
                        onSubmit={searchMethods.handleSubmit(searchJobOpportunity)}
                        alignItems="center"
                        justifyContent="end"
                        flexDirection="row"
                      >
                        <Grid item xs={12} lg={6}>
                          <PageTitle>Vagas Disponíveis</PageTitle>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          lg={6}
                          alignItems="center"
                          justifyContent="end"
                          flexDirection="row"
                        >
                          <Grid item>
                            <SearchComponent
                              label="Pesquisar"
                              placeholder="Pesquise as vagas"
                              name="search"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </FormProvider>
                  </Grid>
                </Grid>

                <Grid item xs={12} mb={2}>
                  {opportunityList.map((item, key) => (
                    <OpportunitiesCardList
                      id={item.id}
                      key={key}
                      title={item.title}
                      state={item.state}
                      requirements={item.requirements}
                      city={item.city}
                      is_pcd={item.is_pcd}
                      type_opportunities_name={item.type_opportunities_name}
                      openModal={(id) => openModal(id)}
                      open_opportunities={item.open_opportunities}
                    />
                  ))}
                </Grid>
              </>
            ) : (
              <Grid item xs={12} mb={2} mt={2} textAlign="center">
                <PageTitle>Não possuÍmos vagas no momento</PageTitle>
              </Grid>
            )}
          </Grid>

          <RegisterModal
            handleClose={() => handleClose()}
            open={open}
            methods={methods}
            onSubmit={onSubmit}
            titleModal="Faça parte da MCS"
            textButton="Enviar currículo"
            hasjobOpportunity={hasjobOpportunity}
            departments={departments}
          />

          {responseSuccess && <SnackBarComponent message={message} success={true} />}
          {responseError && <SnackBarComponent message={message} success={false} />}
          <SuccessDialog
            handleCloseSuccessDialog={() => handleCloseSuccessDialog()}
            titleModal="Enviado com sucesso!"
            contentModal="Sua candidatura foi enviada, manteremos contato por e-mail. Boa sorte!"
            openSuccessDialog={openSuccessDialog}
          />
        </Grid>
      )}
    </PageContainer>
  );
}
