import React from 'react';
import { useController } from 'react-hook-form';
import FormErrors from './FormError';

import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dropZone: {
    maxHeight: '100%',
    minHeight: '10px',
    fullWidth: true,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0,8em',
    color: 'gray',
  },
  previewImg: {
    display: 'none',
  },
}));
type PropsType = {
  name: string;
  fileType: string[] | undefined;
  text: string;
};
const DragAndDrop = ({ name, fileType, text }: PropsType) => {
  const { field, fieldState } = useController({ name });
  const classes = useStyles();
  const [fieldValue, setFieldValue] = React.useState(text);

  const handleDrop = (files: File[]) => {
    setFieldValue(files[0].name);
    field.onChange(files[0]);
  };

  return (
    <>
      <DropzoneArea
        maxFileSize={100000000}
        dropzoneText={fieldValue}
        onChange={field.onChange}
        onDrop={handleDrop}
        acceptedFiles={fileType}
        showPreviewsInDropzone={false}
        showAlerts={false}
        dropzoneClass={classes.dropZone}
      />
      <FormErrors errors={fieldState.error} />
    </>
  );
};

export default DragAndDrop;
