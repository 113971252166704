import React from 'react';

import Typography from '@mui/material/Typography';

type TitleProps = {
  children: string;
};

const PageTitle = ({ children }: TitleProps) => {
  return (
    <Typography
      textTransform="uppercase"
      variant="body1"
      style={{
        fontSize: '20px',
        fontWeight: 'bold',
      }}
      color="primary.main"
    >
      {children}
    </Typography>
  );
};

export default PageTitle;
