import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import JobOpportunity from './JobOpportunity';
import api from '../../services/api/axios-config';
import { AxiosResponse } from 'axios';

const validationSchema = yup.object({
  name: yup.string().required('Esse campo é obrigatório'),
  state: yup.string().required('Esse campo é obrigatório'),
  preferences: yup.string().required('Esse campo é obrigatório'),
});

type JobOpportunity = {
  id: string;
  title: string;
  state: string;
  open_opportunities: number;
  requirements: string;
  city: string;
  type_opportunities_name: string;
  is_pcd: boolean;
};
type Contact = {
  name: string;
  state: string;
  interest_department: string;
  preferences: string;
  resume: never[];
};
type SearchJobOpportunity = {
  search: string;
};
type Department = {
  id: string;
  name: string;
};
function JobOpportunityContainer() {
  /*STATES*/
  const [open, setOpen] = React.useState(false);
  const [oportunityID, setOportunityID] = useState<string>();
  const [hasjobOpportunity, setHasjobOpportunity] = useState<boolean>(false);
  const [responseSuccess, setResponseSuccess] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');
  const [opportunityList, setOpportunityList] = useState<JobOpportunity[]>([]);
  const [departments, setDepartments] = useState([]);
  const [openSuccessDialog, setOpenSuccessDialog] = useState<boolean>(false);
  /*HOOKS*/
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    mode: 'onBlur',
    // Para exibir todos os erros de validação
    criteriaMode: 'all',
    defaultValues: {
      name: '',
      state: '',
      interest_department: '',
      preferences: '',
      resume: [],
    },
    resolver,
  });
  const searchMethods = useForm({
    mode: 'onBlur',
    // Para exibir todos os erros de validação
    criteriaMode: 'all',
    defaultValues: {
      search: '',
    },
  });

  const handleClose = () => {
    setOpen(false);
  };
  const openModalBank = () => {
    setOpen(true);
    setHasjobOpportunity(false);
  };
  function openModal(id?: string) {
    setOportunityID(id);
    setOpen(true);
    setHasjobOpportunity(true);
  }
  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };
  const loadJobOpportunities = async () => {
    try {
      setIsLoading(true);
      const response = await api.get<JobOpportunity[], AxiosResponse>(`/job-opportunities/`);
      setOpportunityList(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const loadDepartments = async () => {
    try {
      const response = await api.get<Department[], AxiosResponse>('/departments/');
      const departmentList = response.data.map((item: Department) => ({
        key: item.id,
        value: item.name,
      }));

      setDepartments(departmentList);
    } catch (error) {
      console.log(error);
    }
  };
  async function onSubmit(data: Contact) {
    setResponseSuccess(false);
    setResponseError(false);
    try {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('interest_department', data.interest_department);
      formData.append('preferences', data.preferences);
      formData.append('resume', data.resume[0]);
      formData.append('state', data.state);
      formData.append('job_opportunity', hasjobOpportunity ? `${oportunityID}` : '');
      await api.post<Contact, AxiosResponse>('/candidates/', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      setOpen(false);
      setOportunityID('');
      methods.reset();
      setResponseSuccess(true);
      setMessage('Currículo enviado');
      setOpenSuccessDialog(true);
    } catch (error) {
      setResponseError(true);
      setMessage('Verifique se o documento foi anexado corretamente');
    }
  }

  const searchJobOpportunity = async ({ search }: SearchJobOpportunity) => {
    try {
      setResponseError(false);
      const response = await api.get<JobOpportunity[], AxiosResponse>(
        `/job-opportunities?search=${search}`
      );
      if (response.data.length > 0) {
        setOpportunityList(response.data);
      } else {
        setResponseError(true);
        setMessage('Nenhuma vaga encontrada com essas característica ');
      }
    } catch (error) {
      setResponseError(true);
      setMessage('Ocorreu algum erro ao pesquisar as vagas');
    }
  };
  useEffect(() => {
    loadJobOpportunities();
    loadDepartments();
  }, []);
  return (
    <JobOpportunity
      opportunityList={opportunityList}
      open={open}
      openModal={openModal}
      handleClose={handleClose}
      methods={methods}
      searchMethods={searchMethods}
      onSubmit={onSubmit}
      openModalBank={openModalBank}
      hasjobOpportunity={hasjobOpportunity}
      departments={departments}
      responseSuccess={responseSuccess}
      responseError={responseError}
      message={message}
      loadJobOpportunities={loadJobOpportunities}
      searchJobOpportunity={searchJobOpportunity}
      isLoading={isLoading}
      openSuccessDialog={openSuccessDialog}
      handleCloseSuccessDialog={handleCloseSuccessDialog}
    />
  );
}

export default JobOpportunityContainer;
