import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

type PopsTypes = {
  openSuccessDialog: boolean;
  titleModal: string;
  contentModal: string;
  handleCloseSuccessDialog: () => void;
};

export default function SuccessDialog({
  openSuccessDialog,
  handleCloseSuccessDialog,
  titleModal,
  contentModal,
}: PopsTypes) {
  return (
    <Dialog
      open={openSuccessDialog}
      onClose={() => handleCloseSuccessDialog()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Grid item xs={12} textAlign="center">
          <Typography
            variant="body1"
            color="primary"
            style={{
              fontSize: '25px',
              fontWeight: 'bold',
            }}
          >
            {titleModal}
          </Typography>
        </Grid>
      </DialogTitle>
      <Grid item xs={12} textAlign="center">
        <Typography
          variant="body1"
          color="GrayText"
          style={{
            fontSize: '20px',
          }}
        >
          {contentModal}
        </Typography>
      </Grid>

      <DialogActions>
        <Button
          variant="contained"
          onClick={() => handleCloseSuccessDialog()}
          sx={{ marginRight: 2 }}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
