import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AccessibleIcon from '@mui/icons-material/Accessible';

export type OpportunitiesCardListProps = {
  key: number;
  id: string;
  title: string;
  state: string;
  requirements: string;
  city: string;
  type_opportunities_name: string;
  is_pcd: boolean;
  open_opportunities: number;
  openModal: (id: string) => void;
};

export function OpportunitiesCardList({
  id,
  state,
  title,
  openModal,
  open_opportunities,
  requirements,
  city,
  type_opportunities_name,
  is_pcd,
}: OpportunitiesCardListProps) {
  const [showDescription, setShowDescription] = useState<boolean>(false);

  const handleDescription = () => setShowDescription(!showDescription);
  return (
    <Grid
      container
      item
      mt={3}
      p={2}
      justifyContent="center"
      alignItems="center"
      sx={{
        borderRadius: '5px',
        boxShadow: 3,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      }}
    >
      <Grid item xs={12} lg={6}>
        <Grid item>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography
              color="primary.main"
              variant="h5"
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              {title}
            </Typography>
            {is_pcd && <AccessibleIcon color="primary" style={{ fontSize: 20 }} />}
            <Typography
              color="gray"
              variant="h5"
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                fontStyle: 'italic',
              }}
            >
              /{type_opportunities_name}
            </Typography>
          </Box>
          <Typography
            color="secondary.main"
            style={{
              fontSize: '15px',
              fontWeight: 'inherit',
            }}
          >
            {city}/{state}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6} textAlign="right">
        <Button color="inherit" variant="contained" startIcon={<BusinessCenterIcon />}>
          {open_opportunities} Vagas
        </Button>
        <Button
          color="inherit"
          variant="contained"
          sx={{ marginLeft: 2 }}
          onClick={() => handleDescription()}
          endIcon={showDescription ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        >
          {showDescription ? 'Ver Menos' : 'Ver Mais'}
        </Button>
      </Grid>
      {showDescription && (
        <Grid item xs={12} mt={2}>
          <Box
            sx={{
              textAlign: 'justify',
              padding: 2,
            }}
          >
            <Typography
              style={{
                whiteSpace: 'pre-line',
              }}
              variant="body2"
            >
              {requirements}
            </Typography>
          </Box>
          <Grid item xs={12} textAlign="center" mt={2}>
            <Button variant="contained" color="primary" onClick={() => openModal(id)}>
              Enviar Currículo
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
