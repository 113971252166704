import React, { Fragment } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import Logo from '../assets/logo.png';

export type MainHeaderType = {
  logoLink: string;
};
export function MainHeader({ logoLink }: MainHeaderType) {
  return (
    <Fragment>
      <AppBar position="fixed" color="inherit">
        <Toolbar>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <a href={logoLink}>
                <img src={Logo} alt="logo" width={96} />
              </a>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}
