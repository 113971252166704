import axios from 'axios';
import { errorInterceptor, responseInterceptor } from './interceptors';

export const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: 'JWT' + localStorage.getItem('access_token'),
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

api.interceptors.response.use(
  (response) => responseInterceptor(response),
  (error) => errorInterceptor(error)
);

export default api;
