import * as React from 'react';

import { useController } from 'react-hook-form';
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FormErrors from './form/FormError';

type PropsTypes = {
  name: string;
  label: string;
  placeholder: string;
};
function SearchComponent({ name, label, placeholder }: PropsTypes) {
  const { field, fieldState } = useController({ name });
  return (
    <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
      <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
      <Input
        id="standard-adornment-password"
        type="text"
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        name={name}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" type="submit">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <FormErrors errors={fieldState.error} />
    </FormControl>
  );
}

export default SearchComponent;
