import { Button, Card, CardContent, Typography } from '@mui/material';
import React from 'react';

export type OpportunityBankCardProps = {
  openModalBank: () => void;
};
export function OpportunityBankCard({ openModalBank }: OpportunityBankCardProps) {
  return (
    <Card
      sx={{
        borderRadius: '5px',
        boxShadow: 3,
        backgroundColor: '#0C7896',
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography
          style={{
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '28px',
            color: '#F6F5F3',
          }}
          color="body1"
          gutterBottom
        >
          Cadastre-se em nosso banco de currículos:
        </Typography>
        <Button variant="contained" color="primary" onClick={() => openModalBank()}>
          Cadastre-se
        </Button>
      </CardContent>
    </Card>
  );
}
