import React from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import { FieldError } from 'react-hook-form';

type PropsTypes = {
  errors: FieldError | undefined;
};

const FormErrors = ({ errors }: PropsTypes) => {
  if (!errors) return null;

  if (errors.types !== undefined) {
    return (
      <FormHelperText error>
        {Object.values(errors.types)
          .map((error) => error)
          .join('. ')}
      </FormHelperText>
    );
  }

  return <FormHelperText error>{errors.message}</FormHelperText>;
};

export default FormErrors;
