import React from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { FormProvider, UseFormReturn } from 'react-hook-form';

import { states } from '../utils/states';
import TextInput from './form/TextInput';
import SelectField from './form/SelectField';
import DragAndDrop from './form/DragAndDrop';
import { DialogTitle } from '@mui/material';

type Contact = {
  name: string;
  state: string;
  interest_department: string;
  preferences: string;
  resume: never[];
};
type PopsTypes = {
  titleModal: string;
  open: boolean;
  hasjobOpportunity: boolean;
  textButton: string;
  departments: { key: string; value: string }[];
  methods: UseFormReturn<
    {
      name: string;
      state: string;
      interest_department: string;
      preferences: string;
      resume: never[];
    },
    unknown
  >;
  handleClose: () => void;
  onSubmit: (data: Contact) => void;
};

export default function RegisterModal({
  titleModal,
  open,
  textButton,
  handleClose,
  methods,
  onSubmit,
  hasjobOpportunity,
  departments,
}: PopsTypes) {
  return (
    <div>
      <Dialog open={open} onClose={() => handleClose()}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <DialogTitle>
            <Grid item xs={12} textAlign="center">
              <Typography
                variant="body1"
                color="primary"
                style={{
                  fontSize: '25px',
                  fontWeight: 'bold',
                }}
              >
                {titleModal}
              </Typography>
            </Grid>
          </DialogTitle>
          <FormProvider {...methods}>
            <Grid
              component="form"
              container
              spacing={3}
              mt={1}
              onSubmit={methods.handleSubmit(onSubmit)}
              alignItems="center"
            >
              <Grid item xs={12} lg={8}>
                <TextInput
                  name="name"
                  label="Nome"
                  placeholder="Qual é o seu nome?"
                  type="text"
                  key="name"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <SelectField
                  name="state"
                  label="Estado"
                  emptylabel="Selecione seu estado"
                  options={states}
                  key="state"
                />
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  name="preferences"
                  label="Preferências"
                  emptylabel="Selecione como você prefere trabalhar"
                  options={[
                    { key: '1', value: 'Híbrido' },
                    { key: '2', value: 'Home Office' },
                    { key: '3', value: 'Presencial' },
                  ]}
                  key="preferences"
                />
              </Grid>
              {!hasjobOpportunity && (
                <Grid item xs={12}>
                  <SelectField
                    name="interest_department"
                    label="Departamento"
                    emptylabel="Selecione o departamento do seu interesse"
                    options={departments}
                    key="state"
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <DragAndDrop
                  name="resume"
                  fileType={['.pdf', '.doc', '.docx']}
                  text="Anexe aqui seu currículo"
                />
              </Grid>

              <Grid item xs={12} textAlign="center">
                <Button variant="outlined" onClick={() => handleClose()} sx={{ marginRight: 2 }}>
                  Cancelar
                </Button>
                <Button variant="contained" type="submit">
                  {textButton}
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </Box>
      </Dialog>
    </div>
  );
}
