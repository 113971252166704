import React from 'react';

import TextField from '@mui/material/TextField';

import { useController } from 'react-hook-form';

import FormErrors from './FormError';

type PropsTypes = {
  type: string;
  name: string;
  label: string;
  placeholder: string;
};

const TextInput = ({ type, name, label, placeholder }: PropsTypes) => {
  const { field, fieldState } = useController({ name });

  return (
    <>
      <TextField
        type={type}
        name={name}
        label={label}
        variant="outlined"
        error={Boolean(fieldState.error)}
        placeholder={placeholder}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        fullWidth
      />

      <FormErrors errors={fieldState.error} />
    </>
  );
};

export default TextInput;
