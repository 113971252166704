import React, { Fragment } from 'react';
import JobOpportunityContainer from './pages/JobOpportunity';

export default function App() {
  return (
    <Fragment>
      <JobOpportunityContainer />
    </Fragment>
  );
}
